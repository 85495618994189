// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;
$screen-md: 						992px !default;
$screen-md-min: 				$screen-md !default;
$grid-float-breakpoint: $screen-md !default;

// Colors
$brand-primary:         rgb(125, 125, 125); /* this is a fallback - primary colour defined in dynamic.css.php through wordpress*/
$text-colour:						rgb(89, 93, 95);
$white:									rgb(255,255,255);
$rgba-black:						rgba(0,0,0,0.7);
$black:									rgb(0,0,0);
$rgba-primary:					rgba(63, 169, 245, 0.7);
$dark-grey:							rgb(34,34,34);
$brand-grey:						rgb(85,85,85);
$light-grey:						rgb(210,210,210);
$v-light-grey:					rgb(242,242,242);
$rgba-white:						rgba(255, 255, 255, 0.1);
$rgba-white-75:					rgba(255,255,255,0.75);

// Fonts
$sans-serif:						'Pontano Sans', sans-serif;
$sans-serif-alt:				'PT Sans', sans-serif;

$column-count:					3;
$column-width:					300px;
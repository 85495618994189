/* special selector used to split the final output file in order to isolate customisations for use with the atlas bootstrap 2 files - do not delete this selector */
php.css-split-selector{ display:none; }

body{
  font-family: $sans-serif;
  font-weight: 300;
  color: $text-colour;
  background-color: $white;
  font-size: 16px;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
  font-family: $sans-serif-alt;
}

.h2, h2{
  text-transform: uppercase;
}

h1, .h1{
  margin-top: 0;
}

blockquote {
  border-left: medium none;
  border-top: 1px solid $brand-grey;
  border-bottom: 1px solid $brand-grey;
  color: var(--background-color);
  font-family: $sans-serif-alt;
  line-height: 1.3;
  margin: 15px 30px 15px 0;
  padding: 10px 5px;
  h1{
    font-size: 1.5em;
    padding: 0;
  }
  h2{
    font-size: 1.2em;
  }
  h3{
    font-size: 1em;
  }
}

div[style*="url("] {
  .bg-transparent, h1, h2, h3, h4{
    color: white;
  }
}

a{
  color: var(--background-color);
  &:hover, &:focus{
    text-decoration: underline;
  }
}

/*
orange : <i class="fa fa-lg fa-square" style="color:rgb(246,162,0)" title="orange"></i>
yellow : <i class="fa fa-lg fa-square" style="color:rgb(224,221,0)" title="yellow"></i>
dark-green : <i class="fa fa-lg fa-square" style="color:rgb(0,102,0)" title="dark-green"></i>
green : <i class="fa fa-lg fa-square" style="color:rgb(122,185,41)" title="green"></i>
teal : <i class="fa fa-lg fa-square" style="color:rgb(0,156,179)" title="teal"></i>
blue : <i class="fa fa-lg fa-square" style="color:rgb(0,159,227)" title="blue"></i>
dark-blue : <i class="fa fa-lg fa-square" style="color:rgb(0,84,159)" title="dark blue"></i>
red : <i class="fa fa-lg fa-square" style="color:rgb(200,40,40)" title="red"></i>
purple : <i class="fa fa-lg fa-square" style="color:rgb(140,29,130)" title="purple"></i>
grey : <i class="fa fa-lg fa-square" style="color:rgb(102,102,102)" title="grey"></i>
v-light-grey : <i class="fa fa-lg fa-square" style="color:rgb(242,242,242)" title="light grey"></i>
primary : <i class="fa fa-lg fa-star" title="primary colour chosen in theme settings"></i>
transparent : <i class="fa fa-lg fa-square-o" title="transparent"></i>
 */

.bg-transparent {
  background-color: transparent;
  .bg-colour-pad {
    padding: 0px;
  }
}

.jumptop{
  position:absolute;
  top:0;
  left:0;
}

.brand {
  display: inline-block;
  height: 45px;
  margin: 7px 0;
  img {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }
}

nav, .nav, .ac_input{
  font-family: $sans-serif-alt;
}
.navbar, .splash{
  margin-bottom: 0;
  border: 0px none;
  min-height: auto;
  position: relative;
}
.splash, .navigation{
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
}
.splash[style*="url("]{
  background-size: cover;
  background-position: center center;
}
.splash, .splash .carousel-inner{
  min-height: 300px;
  height: 35vh;
  z-index: 5;
}
.admin-bar{
  .splash, .splash .carousel-inner{
    min-height: 300px;
    height: 35vh;
  }
}

.home{
  .splash, .splash .carousel-inner{
    min-height: 300px;
    height: calc(100vh - 65px);
  }
  &.admin-bar{
    .splash, .splash .carousel-inner{
      min-height: 300px;
      height: calc(100vh - 111px); /* 65px + 46px */
    }
  }
}
.navigation{
  background-color: black;
  z-index: 100;
}

.navbar-xs {
  align-content: center;
  display: flex;
  justify-content: space-between;
  min-height: 65px;
  padding-left: 15px;
  position: relative;
  .navbar-toggle {
    border-radius: 0;
    border-color: $brand-primary;
    border-color: var(--background-color);
    padding: 6px 10px;
    .icn-bar {
      border-radius: 1px;
      display: block;
      height: 2px;
      width: 22px;
      margin: 4px 0;
      background-color: $brand-primary;
      background-color: var(--background-color);
    }
    &:hover {
      background-color: $brand-primary;
      background-color: var(--background-color);
      .icn-bar{
        background-color: $white;
      }
    }
  }
}

.navbar-apex{
  background-color: $brand-primary;
  background-color: var(--background-color);
  font-size: 13px;
  .nav{
    float: right;
    text-transform: uppercase;
    > li {
      border-left: 1px solid rgba(255,255,255,0.25);
      > a {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  }
  .navbar-form{
    margin: 6px 0;
    input{
      background-color: rgba(255, 255, 255, 0.05) !important;
      border: 1px solid rgba(255,255,255,0.25);
      color: white;
      height: 28px;
      padding: 0 0 0 10px;
    }
    .form-control::-moz-placeholder,
    .form-control::-webkit-input-placeholder,
    .form-control::-ms-input-placeholder{
      color: white !important;
    }
    .btn{
      padding-top: 3px;
      padding-bottom: 3px;
      color: white;
    }
  }
}

/*
.main-search input::-moz-placeholder, .main-search input::-webkit-input-placeholder, .main-search input::input-placeholder{
   color: $brand-primary;
  color: var(--color);
}
*/

.navbar-global{
  background-color: white;
  .nav > li > a {
    color: $dark-grey;
    font-size: 12px;
    line-height: 100%;
    padding: 10px 5px 10px 15px;
    text-transform: uppercase;
  }
}
.menu-choose-atlas{
  > a::after {
    content: "";
    display: inline-block;
    font-family: fontawesome;
    font-weight: 100;
    padding-left: 8px;
  }
  > a::before {
    content: "";
    display: inline-block;
    font-family: fontawesome;
    font-weight: 100;
    padding-right: 10px;
  }
}
.menu-log-in > a::before {
    content: "";
    display: inline-block;
    font-family: fontawesome;
    font-weight: 100;
    padding-right: 10px;
}

/* PRIMARY NAV */
.dropdown-menu{
  border: 0 !important;
  box-shadow: none !important;
  padding: 1px 0;
}
.navbar-primary{
  .navbar-nav{
    .dropdown{
      .dropdown-toggle::after {
        content: "";
        font-family: fontawesome;
        padding-left: 8px;
      }
    }
    .dropdown-menu{
      background-color: $brand-primary;
      background-color: var(--background-color);
      font-size: 1em;
      > li{
        > a{
          padding: 7px 15px;
          color: white;
          &:hover,&:focus{
            background-color: $brand-grey;
            color: white;
            text-decoration: none;
            background-image: none;
          }
        }
      }
    }
  }
  .nav{
    > li{
      > a{
        text-transform: uppercase;
        color: $white;
        &:focus, &:hover{
          background-color: $brand-primary;
          background-color: var(--background-color);
          text-decoration: none;
        }
      }
    }
    .open{
      > a, &:focus, &:hover{
        background-color: $brand-primary;
        background-color: var(--background-color);
        text-decoration: none;      }
    }
  }
}

/* apex nav */
.navbar-apex{
  .nav{
    .open{
      .dropdown-menu{
        background-color: $brand-primary;
        background-color: var(--background-color);
        color: $white;
      }
    }
    > li > a{
      color: white;
      text-shadow: none;
    }
    > li > a:hover, .open > a:hover{
      background-color: $white;
      color: $brand-primary;
      color: var(--color);
      text-decoration: none;
    }
    > li > a:focus, .open > a, .open > a:focus {
      background-color: $brand-primary;
      background-color: var(--background-color);
      color: $white;
      text-decoration: none;
    }
  }
  .dropdown-menu{
    border: 0 !important;
    padding: 1px 0;
    background-color: $brand-primary;
    background-color: var(--background-color);
    > li > a{
      padding: 4px 15px;
      color: $white;
      &:focus, &:hover{
        background-color: $white;
        color: $black;
        text-decoration: none;
        background-image: none;
      }
    }
  }
}

/* CAROUSEL */
.carousel-fade {
  .carousel-inner {
   > .item {
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    position: absolute;
    z-index:0;
    transition: none;
    transform: translate3d(0,0,0) !important;
    right: 0;
    bottom: 0;
  }
   > .active {
    opacity: 1;
    z-index:3;
  }

   > .next.left,
   > .prev.right {
    transition: opacity 0.7s ease-in;
    opacity: 1;
    left: 0;
    z-index:2;
  }
  > .active.left,
  > .active.right {
    z-index:1;
  }
}
.carousel-control {
  z-index:4;
  height: 160px;
  bottom: auto;
  top: calc(50% - 80px);
}
}
.carousel{
  .carousel-control.left, .carousel-control.right{
    background-image: none !important;
    text-shadow: none;
    z-index: 50;
    width: 8vw;
  }
  .carousel-control::after{
    color: rgba(255, 255, 255, 0.2);
    display: block;
    font-family: fontawesome;
    font-size: 100px;
    line-height: 1;
    position: absolute;
    top: calc(50% - 50px);
  }
  .right.carousel-control::after {
    content: "";
    right: 10%;
  }
  .left.carousel-control::after {
    content: "";
    left: 10%;
  }
  .carousel-indicators{
    li{
      border: 0px none;
      background: rgba(white, 0.5);
      &.active{
        height: 10px;
        width: 10px;
        background: rgba(white, 1);
        margin: 1px;
      }
    }
  }
}

.banner{
  .carousel{
    @extend .carousel-fade;
    .carousel-inner {
      .item {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        .carousel-caption {
          align-content: center;
          color: #fff;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          left: 15%;
          right: 15%;
          text-align: center;
          top: 10%;
          .btn {
            color: white;
            border-color: white;
            background-color: rgba(black, 0.1);
          }
          h1 {
            font-family: $sans-serif;
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 20px;
          }
          .carousel-description{
            margin-bottom: 40px;
          }
          h1, p{
            padding: 0;
            text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
          }
        }
      }
    }
  }
}

.atlas-species-random-list, .atlas-remote-data-list{
  ul {
    display: flex;
    flex-flow: row wrap;
    list-style: outside none none;
    padding: 0;
    li {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      flex: 1 1 auto;
      font-family: PT Sans;
      font-size: 1.2em;
      font-weight: 600;
      margin: 0 10px 10px 0;
      padding: 5px 12px;
      position: relative;
      min-width: 20%;
      small {
        display: block;
        font-weight: 200;
      }
      a{
        color: white;
        display: block;
        &:hover{
          text-decoration: none;
          &:after{
            content: "";
            display: block;
            font-family: fontawesome;
            font-size: 14px;
            font-weight: 100;
            height: 12px;
            line-height: 12px;
            position: absolute;
            right: 3px;
            top: 3px;
            width: 12px;
          }
        }
      }
      &:hover{
        background-color: white;
        a{
          color: var(--background-color);
        }
      }
    }
  }
}

/* MAIN SEARCH */
.main-search-container {
  bottom: 30px;
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  z-index: 999;
  .main-search {
    display: block;
    margin: 0 auto;
    max-width: 380px;
    position: relative;
    padding: 0 10px;
    .btn {
      background: #fff none repeat scroll 0 0;
      border-left: 1px dotted #e4e4e4;
      padding: 8px 11px;
      position: absolute;
      right: 11px;
      top: 1px;
    }
    input {
      border: 1px solid #fff;
      box-shadow: none;
      font-size: 1em;
      height: 40px;
      padding: 8px 12px;
      color: #000;
      &.tt-hint{
        color: #999;
      }
    }
  }
}

/**
 * content blocks
 */
.content-blocks{
  &.row, > .container{
    padding-top: 15px;
  }
  .content-block{
    .block-buttons{
      p{
        font-size: 1em !important;
      }
    }
  }
  /*
  > div.container:first-child{
      margin-top: 40px;
  }
  */
  > div.full-width:first-child{
    .content-block.panel_grid:first-child{
      margin-top: 0;
    }
  }
  .lead-intro{
    padding-top: 15px;
    p:first-of-type{
      font-size: 1.35em;
      font-weight: 300;
    }
    .woocommerce{
      padding-top: 20px;
    }
  }
  .main-width{
    .call-out{
      @extend .col-md-4;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: -33%;
      h4{
        color: $text-colour;
      }
    }
  }
  .full-width{
    .panel_grid{
      .tab-pane.active{
        padding: 0;
      }
    }
  }
  .full-width:last-child{
    margin-bottom: 0;
  }

  .expanding_text {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    padding-bottom: 20px;
    .btn.btn-text{
      text-transform: uppercase;
      padding-left: 0;
      font-size: 0.9em;
      &:after {
        font-family: 'fontawesome';
        padding-left: 10px;
        content: "";
      }
      &.collapsed:after {
        content: "";
      }
    }
  }

  .full_width_image{
    div[style*="url("]{
      position: relative;
      .btn-photo-info{
        display: none;
      }
    }
  }
  .carousel{
    .carousel-inner {
      .item {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 50vh;
        .carousel-caption {
          text-align: left;
          right: auto;
          left: 15%;
          top: 0px;
          color: white;
          .btn {
            color: white;
            border-color: white;
            background-color: rgba(black, 0.1);
          }
          h1 {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 20px;
          }
          p {
            font-size: 1.2em;
          }
          .carousel-description{
            margin-bottom: 40px;
          }
          h1, p{
            padding: 0;
            text-shadow: 1px 1px 4px #000;
          }
        }
        .feature_panel{
          .columns{
            min-height: 50vh;
          }
        }
      }
    }
    .carousel-indicators{
      left: auto;
      text-align: right;
      right: 4%;
      bottom: 15px;
    }
    .show-thumbnails{
      margin-left: auto;
      margin-right: auto;
      .carousel-inner{
        .item{
          .carousel-caption{
            background-color: rgba(0, 0, 0, 0.5);
            bottom: 0;
            color: #fff;
            left: 0;
            padding: 0 30px 15px;
            right: 0;
            text-align: left;
            top: auto;
            width: 100%;
          }
        }
      }
      .carousel-thumbnails {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > a {
          flex: 1 1 auto;
          max-width: 33%;
          min-width: 32%;
          .thumbnail{
            min-height: 17.5vh;
          }
        }
      }
    }
  }
  .panel_grid{
    .panel-group{
      margin-bottom: 0;
      .panel{
        box-shadow: none;
      }
      .panel-heading{
        .panel-title{
          > a{
            display: block;
            &:after {
              font-family: 'fontawesome';
              float: right;
              content: "";
            }
            &.collapsed:after {
              content: "";
            }
          }
        }
      }
    }
    .tab-pane.active{
      padding: 15px;
      margin-bottom: 20px;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    .nav-tabs{
      > li{
        > a{
          font-weight: 600;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          border-top: 1px solid #ddd;
          border-radius: 0;
          color: $brand-grey;
          margin-right: 4px;
        }
        &.active{
          > a, a:hover, a:focus{
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-radius: 0;
            color: $brand-grey;
          }
        }
      }
    }
  }
  .call_to_action{
    .bg-overlay{
      background-color: rgba(0,0,0,0.3);
      background-blend-mode: darken;
    }
    div[style*="url("]{
      background-position: center 35%;
      background-size: cover;
      background-attachment: local;
    }
    .cta-blocks{
      flex-direction: column;
      display: flex;
      flex: 1 1 auto;
      justify-content: stretch;
      &.cta-individual{
        .cta-content{
          margin-bottom: 10px;
        }
      }
      .cta-split {
        display: flex;
        flex-flow: column nowrap;
        .cta-split-image{
          height: 50%;
          flex: 1 1 60%;
        }
        .cta-text-copy{
          padding: 15px 3vw;
          flex: 0 0 40%;
        }
      }
      .cta-content{
        min-height: 45vh;
        display: flex;
        align-items: start;
        &.cta-vcentre{
          align-items: center;
        }
        .cta-text-copy{
          flex: 0 1 auto;
          margin: 0 auto;
          h2, .h2{
            margin-top: 15px;
            margin-bottom: 20px;
          }
          p{
            font-size: 1.3em;
          }
          img{
            max-width: 35vh;
          }
        }
        p, dt{
          font-weight: 300;
          font-size: 1.1em;
        }
        dt{
          font-weight: 500;
          padding: .25em 0;
        }
        .text-left{
          .btn{
            margin: 0 15px 0 0;
          }
        }
        .text-left{
          .btn{
            margin: 0 0 0 15px;
          }
        }
        .text-center{
          .btn{
            margin: 0 15px;
          }
        }
        &.col-md-4{
          padding: 5vh 3vw;
          /*justify-content: space-between;*/
        }
        &.col-md-6{
          padding: 5vh 5vw;
          /*justify-content: space-between;*/
        }
        &.col-md-12{
          padding: 5vh 6vw;
          /*justify-content: center;*/
        }
        .cta-icon {
          min-height: 10vh;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
        &.cta-split{
          padding: 0;
        }
      }
      .block-buttons{
        margin-top: 15px;
      }
    }
  }
  .listings_grid{
    .listing-grid {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-end;
      align-items: stretch;
      > div{
        min-height: 50vh;
        flex: 1 1 auto;
        margin: 0 5px 5px 0;
      }
      .listing-item{
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        > a{
          bottom: 0;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-flow: column;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          background-color: rgba(0,0,0,0.5);
          transition: all 0.5s;
          &.lead{
            font-weight: 600;
            color: white;
            margin: 0;
            text-shadow: 0 0 4px black;
            &:hover{
              background-color: unset;
              transition: all 0.5s;
              text-decoration: none !important;
              text-shadow: 0 0 15px #000, 0 0 2px #000;
            }
          }
        }
        &[style*="url("]{
          background-position: 50% 45%;
          background-size: cover;
          background-repeat: no-repeat;
        }
        &.listing-item-text{
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;
          img{
            max-width: 40%;
            display: inline-block;
            padding: 10px 0 10px 10px;
          }
        }
      }
      .column-text{
        padding: 20px 40px 30px;
        > a{
          padding: 20px 40px 30px !important;
        }
      }
    }
    .listing-group{
      padding: 0;
      margin-bottom: 0 !important;
      .listing-item{
        height: calc(50% - 5px);
        position: relative;
        width: 100%;
        margin-bottom: 5px;
        min-height: 49vh;
      }
    }
    .listing-grid-1, .listing-grid-2, .listing-grid-3{
      > div.listing-item{
        > a{
          top: auto;
          height: 33%;
          padding: 15px;
          line-height: 1;
          text-align: left;
          text-shadow: none !important;
          &:before{
            top: -200%;
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            right: 0;
          }
          &:after{
            bottom: 15px;
            content: 'View details';
            position: absolute;
            display: block;
            font-weight: 300;
            font-size: 13px;
          }
          &:hover{
            background-color: inherit !important;
            &:after{
              text-decoration: underline;
            }
          }
        }
      }
    }
    .listing-grid-4, .listing-grid-5, .listing-grid-6{
      flex-flow: row wrap;
    }
    .listing-grid-5,.listing-grid-6{
      > div.listing-item{
        min-height: 25vh;
        min-width: 45%;
      }
    }
  }
}

.page-subnav{
  padding-top: 15px;
  clear: both;
  > ul {
    list-style: outside none none;
    padding: 0;
    > li{
      > a{
        &::after {
          content: "";
          font-family: fontawesome;
          position: absolute;
          right: 10px;
          color: $light-grey;
          z-index: 99;
          opacity: 0.5;
        }
      }
      &:first-child{
        > a{
          border-top: 1px solid $v-light-grey;
        }
      }
    }
  }
  .children {
    display: none;
    list-style: outside none none;
    padding: 0;
    li{
      @extend .small;
      a{
        padding: 8px 30px 8px 38px;
      }
    }
  }
  li{
    position: relative;
    margin-bottom: 2px;
    a{
      background-color: $brand-grey;
      color: white;
      display: block;
      padding: 10px 30px;
    }
    a:hover{
      background-color: var(--background-color);
      text-decoration: none;
    }
  }
  li.current_page_item{
    li{
      margin-bottom: 0px;
    }
    > a{
      background-color: var(--background-color);
      text-decoration: underline;
      color: white;
      &::after {
        content: '';
        opacity: 1;
        color: white;
      }
    }
  }
  .current_page_ancestor, .current_page_item{
    > .children{
      display: block;
    }
  }
}


.main-width {
  @extend .col-md-9;
  @extend .clearfix;
  margin-bottom: 30px;
  .bg-colour-pad {
    padding: 30px;
  }
}

.full-width{
  @extend .clearfix;
  margin-bottom: 15px;
  .full_width_image{
    div[style*="url("]{
      height: 55vh;
    }
  }
}

.full-indent-width {
  min-height: 1px;
  position: relative;
  margin-bottom: 15px;
  .aside-width {
    display: none;
  }
  &.page-search{
    margin-top: 40px;
  }
}

.full-width, .full-indent-width{
  .columns{
    .row{
      min-width: calc(100% + 30px);
    }
    .column-text{
      h3{
        font-size: 32px;
      }
    }
  }
}

.aside-width {
  @extend .col-md-3;
  @extend .clearfix;
  margin-bottom: 30px;
  h4:first-child{
    margin: .25em 0 .5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: .5em;
  }
}


.tt-menu {
    background-color: $brand-primary;
  background-color: var(--background-color);
    width: 100%;
}
.tt-menu.tt-open {
    z-index: 9999 !important;
}
.tt-selectable{
    display: block;
    cursor: pointer;
    padding: 5px 15px;
    color: $white;
    strong{
      font-weight: 300 !important;
    }
}
.tt-selectable:hover{
    background-color: $brand-primary;
  background-color: var(--background-color);
    color: $white;
}

button, input, select, textarea, .dropdown-menu{
  border-radius: 0 !important;
}


.feat-data {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  padding: 10px 0 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  transition: all 0.5s;
  .species-cat {
    color: white;
    display: block;
    font-family: PT Sans,sans-serif;
    font-size: 13px;
    margin: 10px 0;
    text-align: center;
    text-transform: uppercase;
  }
  > h3 {
      color: $white;
      font-weight: normal;
      line-height: 175%;
      text-transform: uppercase;
    > small {
      font-family: $sans-serif-alt;
      color: $white;
      display: block;
      text-transform: none;
      font-size: 85%;
      font-style: italic;
    }
  }
  .feat-links {
      margin-top: 10px;
    > p {
      margin: 0 0 5px;
    }
  }
}

/* ICON LINK */
a.icn-link {
  background-color: $brand-primary;
  background-color: var(--background-color);
  color: $white;
  border-radius: 15px 4px 4px 15px;
  padding: 3px 10px 3px 2.25em;
  text-shadow: none;
  display: inline-block;
  position: relative;
  &:before {
    background-color: white;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    width: 26px;
    top: calc(50% - 13px);
    height: 26px;
  }
  &:after {
    content: '';
    font-family: fontawesome;
    color: $text-colour;
    display: block;
    position: absolute;
    left: 2px;
    top: calc(50% - 13px);
    width: 26px;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
  }
  &[target="_blank"]::after {
    content: "\f14c";
    font-size: 0.9em;
  }
  &:active, &:focus, &:hover {
    background-color: $white;
    color: $brand-primary;
    color: var(--background-color);
    text-decoration: none;
    transition: all 0.2s linear 0s;
    &:before {
      background-color: var(--background-color);
      transition: all 0.2s linear 0s;
    }
    &:after {
      color: white;
      transition: all 0.2s linear 0s;
    }
  }
  &.icn-map{
    &:after{
      content: '';
    }
  }
  &.icn-top{
    color: $brand-grey;
    background-color: transparent;
    &:before{
      background-color: $text-colour;
    }
    &:after{
      content: '';
      color: white;
    }
  }
}

.breadcrumb-container{
  .breadcrumb{
    @extend .small;
  }
}

.cta-home{
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;
  .cta {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    .menu-item{
      i{
        font-size: 1.25em;
      }
      h3 {
        color: $white;
        display: block;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        pointer-events: none;
        padding: 5px 0;
        font-size: 20px;
      }
      a {
        min-height: 110px;
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        justify-content: space-around;
        background-color: $brand-grey;
        border-top: 1px solid $white;
        color: #fff;
        font-weight: 300;
        text-align: center;
        padding: 5px 0;
        font-size: 22px;
        position: relative;
        &:focus, &:hover {
          text-decoration: none;
          background-color: $brand-primary;
          background-color: var(--background-color);
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.50);
        }
      }
      small {
        color: $white;
        display:block;
        text-transform: none;
        line-height: 1.3;
        font-size: 17px;
      }
    }
  }
}

.info-panel, .full-info-panel {
    background-position: center center;
    background-size: cover;
    color: #fff;
    min-height: 400px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
}
.full-sponsor-panel{
    min-height: 0;
}
.info-panel .link-block{
  position:absolute;
  display:block;
  bottom:15px;
}
.info-panel .col-md-6:first-child p {
    font-size: 110%;
}
.info-panel p {
    margin-top: 20px;
}

.full-panel {
    text-align: center;
}
.full-info-panel{
  padding: 30px 20%;
}
.full-panel h2, .full-panel h2{
    text-transform: uppercase;
  text-align: center;
  text-size: 34px;
}
.full-info-panel p{
    font-size: 1.3em;
    margin: 10px 0 20px;
}


.data-partners{
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-content: center;
  text-align: center;
  > p {
    margin: 0 auto 10px;
    width: calc(100vw - 120px);
  }
  .partner{
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 46vh;
    text-align: left;
    .partner-img {
      background-image: url('/wp-content/themes/nbnatlas/dist/images/data-provider.png');
      background-position: 50% 65%;
      background-size: cover;
      height: 30vh;
      max-height: 250px;
      min-height: 150px;
      position: relative;
      border-color: $light-grey;
      border-style: solid solid none;
      border-width: 1px 1px 0;
    }
    .partner-copy{
      border-color: $light-grey;
      border-style: none solid solid;
      border-width: 0 1px 1px;
      padding: 15px;
      display: flex;
      flex-flow: column nowrap;
      height: 30vh;
      min-height: 250px;
      > p{
        flex: 1 1 auto;
      }
      > h3{
        flex: 0 1 auto;
      }
      > a.partner-logo{
        display: block;
        height: auto;
        max-height: 80px;
        max-width: 50%;
        width: auto;
        flex: 1 1 auto;
        img {
          height: auto;
          max-height: 70px;
          max-width: 170px;
          width: auto;
        }
      }
    }
  }
  .partners-carousel{
    .item{
      min-height: 0 !important;
    }
    .cloneditem-1,.cloneditem-2,.cloneditem-3 {
      display: none;
    }
    .carousel-control{
      background: $brand-grey;
      width: 30px;
      height: 30px;
      top: -40px;
      border-radius: 50%;
      .fa {
        background-color: $brand-grey;
        border-radius: 50%;
        height: 30px;
        line-height: 30px;
        position: absolute;
        top: -40px;
        width: 30px;
      }
      &.right{
        right: 15px;
      }
      &.left{
        left: 15px;
      }
      &::after {
        color: #fff;
        font-size: 1.5em;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
}

footer{
  .footer-top{
    min-height: 80px;
    padding: 25px 15px;
    background: $v-light-grey;
  }
  .footer-bottom{
    background: $brand-primary;
    color:$white;
    padding-top: 20px;
    padding-bottom: 20px;
    a {
      color:white;
      display: block;
      padding: 4px;
      border-radius: 4px;
      &:focus, &:hover, &:active{
        background-color: $brand-primary;
        background-color: var(--background-color);
        color: $white;
        text-decoration: none;
        transition: all 0.2s linear 0s;
      }
    }
    .copyright{
      a.brand{
        display: inline-block;
        margin: 7px 15px 15px 0;
        vertical-align: bottom;
      }
    }
  }
  h6{
    color:$white;
    text-transform:uppercase;
    font-size: 15px;
  }
  .footer-nav{
    padding-top:15px;
    line-height: 110%;
    list-style:none;
    padding-left:0px;
    > li {
      border-bottom: 1px dotted $white;
      padding-bottom: 10px;
      &:last-child{
        border-bottom: 0px none;
      }
      > a {
        text-transform: uppercase;
        display: block;
      }
    }
    ul {
      padding-left: 0;
      list-style: none;
      a {
        font-size: 85%;
      }
    }
  }
  .footer-nav2{
    list-style: outside none none;
    margin: 5px 0;
    padding: 10px 0;
  }
  .footer-bottom .col-sm-12 {
      margin-top: 10px;
  }
  .sponsors{
    background-color: rgba($v-light-grey,0.35);
    min-height: 35vh;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 40px;
    justify-content: space-around;
    h4, .h3{
      text-transform: uppercase;
    }
    .sponsor-links{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      a{
        flex: 1 1 auto;
        text-align: center;
        margin: 20px 30px 0;
        img{
          max-height: 80px;
          width: auto;
        }
      }
    }
  }
  h4, .h4{
    text-transform: uppercase;
  }
  .social-links{
    padding: 15px 0 0;
    h4{
      &:first-child{
        margin-top: 0;
      }
    }
    > a{
      display: inline-block;
      > span{
        display: none;
      }
    }
  }
  .footer-extra{
    padding: 17px 0 0;
  }
}

.back-to-top{
  color:$brand-grey;
  padding:20px 15px;
  display:inline-block;
}

.twitter-typeahead{
    display: block !important;
    postiion: static !important;
}

.block-full-panels a[href*="environment.scotland.gov.uk"] img {
    background-color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
    width: auto !important;
}


.image-caption{
  height: 50px;
  width: 50px;
  top: 0px;
  left: 0px;
  position: absolute;
  display: table;
  vertical-align: middle;
  table-layout: fixed;
  z-index: 900;
  > a{
    color: rgba(255,255,255,0.5) !important;
    height: 50px;
    width: 50px;
    line-height: 35px;
  }
  > div{
    display: none;
    min-height: 50px;
    width: 100%;
    padding: 5px 15px 5px 50px;
    overflow: hidden;
  }
  &:hover,&:focus{
    width: 100%;
    .btn-photo-info{
      pointer-events: none;
      color: white;
      i{
        pointer-events: none;
      }
    }
    > div{
      z-index: 999;
      opacity: 0;
      transition: opacity 0.5s;
      display: table-cell;
      color: white;
      vertical-align: middle;
      &:hover,&:focus{
        opacity: 1;
        transition: opacity 0.5s;
        background-color: rgba(0,0,0,0.5);
      }
    }
  }
}



@media all and (min-width: 768px) {
  .partners-carousel .carousel-inner > .active.left,
  .partners-carousel .carousel-inner > .prev {
    left: -50%;
  }
  .partners-carousel .carousel-inner > .active.right,
  .partners-carousel .carousel-inner > .next {
    left: 50%;
  }
  .partners-carousel .carousel-inner > .left,
  .partners-carousel .carousel-inner > .prev.right,
  .partners-carousel .carousel-inner > .active {
    left: 0;
  }
  .partners-carousel .carousel-inner .cloneditem-1 {
    display: block;
  }
}
@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
  .partners-carousel .carousel-inner > .item.active.right,
  .partners-carousel .carousel-inner > .item.next {
    -webkit-transform: translate3d(50%, 0, 0);
            transform: translate3d(50%, 0, 0);
    left: 0;
  }
  .partners-carousel .carousel-inner > .item.active.left,
  .partners-carousel .carousel-inner > .item.prev {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    left: 0;
  }
  .partners-carousel .carousel-inner > .item.left,
  .partners-carousel .carousel-inner > .item.prev.right,
  .partners-carousel .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    left: 0;
  }
}
@media all and (min-width: 992px) {
  .partners-carousel .carousel-inner > .active.left,
  .partners-carousel .carousel-inner > .prev {
    left: -25%;
  }
  .partners-carousel .carousel-inner > .active.right,
  .partners-carousel .carousel-inner > .next {
    left: 25%;
  }
  .partners-carousel .carousel-inner > .left,
  .partners-carousel .carousel-inner > .prev.right,
  .partners-carousel .carousel-inner > .active {
    left: 0;
  }
  .partners-carousel .carousel-inner .cloneditem-2,
  .partners-carousel .carousel-inner .cloneditem-3 {
    display: block;
  }
    .partners-carousel .carousel-control{
        width: 5%;
    }
}
@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
  .partners-carousel .carousel-inner > .item.active.right,
  .partners-carousel .carousel-inner > .item.next {
    -webkit-transform: translate3d(25%, 0, 0);
            transform: translate3d(25%, 0, 0);
    left: 0;
  }
  .partners-carousel .carousel-inner > .item.active.left,
  .partners-carousel .carousel-inner > .item.prev {
    -webkit-transform: translate3d(-25%, 0, 0);
            transform: translate3d(-25%, 0, 0);
    left: 0;
  }
  .partners-carousel .carousel-inner > .item.left,
  .partners-carousel .carousel-inner > .item.prev.right,
  .partners-carousel .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    left: 0;
  }
}

/* media queries */
@media (min-width: $screen-sm-min) {
  .banner .container,.banner .container-fluid{
    background-color: transparent;
  }
  .cta-home {
    .cta{
      .menu-item{
        a{
          float: left;
        }
        a:nth-child(odd){
          margin-right: 1px;
          width: calc(50% - 1px);
        }
        a:nth-child(even){
          width: 50%;
        }
      }
    }
  }
  .carousel-caption p{
    font-size:32px;
  }
  .feat-links {
      text-align: center;
  }
  .data-partners .full-panel {
      padding: 0 20%;
  }
  .brand{
    margin: 7px 15px 4px;
  }
  .main-search input {
    font-size: 18px;
  }
  footer{
    .sponsors{
      .sponsor-links{
        flex-flow: row nowrap;
        a{
          img{
            max-height: 125px;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {

  .main-search-container {
    bottom: 165px;
  }
  .navbar-primary.affix {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 900;
  }
  .navbar-collapse{
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-primary{
    background-color: $rgba-black;
    > .nav {
      > li{
        > a{
          padding: 20px 10px;
        }
      }
    }
  }

  .navbar-primary, .navbar-apex{
    > .nav {
      display: block;
      float: right;
      > .menu-item.open:last-child{
        > .dropdown-menu{
          right: 0;
          left: auto;
        }
      }
    }
  }

  .aside-width{
    float: left !important;
    clear: left;
  }
  .main-width{
    float: right !important;
    clear: none;
  }

  .banner{
    .carousel{
      .carousel-inner {
        .item {
          .carousel-caption {
            bottom: auto;
            h1 {
              font-size: 3em;
            }
            p {
              font-size: 1.5em;
            }
            h1, p{
              //text-shadow: none;
            }

          }
        }
      }
    }

    .image-caption{
      > a{
        color: white;
      }
    }
  }

  .content-blocks{
    .row{
      .full-indent-width {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .full_width_image{
      div[style*="url("]{
        .btn-photo-info{
          display: block;
          bottom: 15px;
          left: 15px;
          right: auto;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 1em;
          z-index: 999;
          pointer-events: none;
          &:hover,&:focus{
            border-radius: 100%;
          }
        }
      }
    }
    .feature_panel{
      .columns{
        flex-wrap: nowrap;
      }
    }
    .listings_grid{
      .listing-grid {
        flex-flow: row nowrap;
        > div{
          margin: 0 5px 5px 0;
        }
        .listing-item{
          &:nth-child(3n), &:last-child{
            margin-right: 0px;
          }
        }
      }
      .listing-group{
        .listing-item{
          &:nth-child(n+2){
            margin-bottom: 0px;
          }
        }
        &:last-child{
          margin-right: 0px;
        }
      }
      .listing-grid-3{
        > div:nth-child(2n){
          .listing-item{
            height: calc(100% - 5px);
          }
        }
      }
      .listing-grid-4{
        > div.listing-item{
          max-width: 33%;
        }
      }
      .listing-grid-5{
        flex-flow: row wrap;
        justify-content: flex-start;
        > div{
          min-height: 25vh;
          margin-right: 0;
          max-width: calc(33.3% - 3.3px);
          &:first-child,&:nth-child(3n+1),&:nth-child(3n+2){
            margin-right: 5px;
          }
          &.listing-item{
            min-width: 25%;
            min-height: 24vh;
          }
        }
      }
    }
    .call_to_action{
      .cta-blocks{
        flex-direction: row;
        &.cta-individual{
          .cta-content{
            margin-left: 7.5px;
            margin-right: 7.5px;
            margin-bottom: 0px;
            &:first-child{
              margin-left:0;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
    .carousel{
      .show-thumbnails {
        margin-left: auto;
        margin-right: auto;
        .carousel-thumbnails {
          > a {
            max-width: 24.5%;
            min-width: 24%;
            .thumbnail{
              min-height: 25vh;
            }
          }
        }
      }
    }
    .height-auto{
      min-height: auto !important;
    }
    &.common-blocks{
      > div{
        margin-bottom: 0;
      }
    }
  }


  .cta-home {
    bottom: 0;
    height: 170px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 150;
    .cta{
      .menu-item{
        a{
          min-height: 170px;
          padding: 10px 0;
          background-color: $rgba-black;
          margin: 0 2px;
          border-top: 0px none;
          z-index: 300;
          width: calc(25% - 3px) !important;
          float: left;
        }
        i{
          font-size: 1.75em;
        }
        h3{
          font-size: 24px;
          padding: 0;
        }
      }
      li:last-child a{
        margin-right: 0;
      }
      li:first-child a{
        margin-left: 0;
      }
    }
  }

  .content-blocks{
    .row{
      .full-indent-width {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .full_width_image{
      div[style*="url("]{
        .btn-photo-info{
          display: block;
          bottom: 15px;
          left: 15px;
          right: auto;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 1em;
          z-index: 999;
          pointer-events: none;
          &:hover,&:focus{
            border-radius: 100%;
          }
        }
      }
    }
  }

  footer{
    .footer-nav{
      display: flex;
      flex-flow: column wrap;
      > li {
        flex: 1 1 auto;
        padding: 0 20px 0 0;
        border-bottom: 0px none;
        padding-bottom: 0;
        font-size: 1.15em;
        > a{
          margin-bottom: 10px;
        }
        ul li{
          display: inline-block;
        }
      }
    }
    .copyright{
      margin:40px 0 10px;
      > p, .footer-nav2{
        display: inline-block;
      }
    }
    .footer-nav2{
      border-left: 1px solid #fff;
      line-height: 1;
      margin: 0 0 0 30px;
      padding: 0 0 0 20px;
    }
  }

  .modal {
    text-align: center;
    &:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }

  .splash, .splash .carousel-inner{
    min-height: 40vh;
  }
  .admin-bar{
    .splash, .splash .carousel-inner{
      min-height: 40vh;
    }
  }
  .home{
    .splash, .splash .carousel-inner{
      min-height: 450px;
      height: calc(100vh - 133px);
    }
    &.admin-bar{
      .splash, .splash .carousel-inner{
        min-height: 450px;
        height: calc(100vh - 165px);
      }
    }
  }
  .navigation{
    height: 133px;
    background-color: transparent;
  }
  .navbar-global .nav > li.menu-scotlands-environment-web > a {
    background-image: url("//www.als.scot/wp-content/uploads/2015/08/part-of-scotlands-environment-web-logo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80% auto;
    color: transparent;
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar-global .navbar-nav {
    position: relative;
    width: 100%;
  }
  .navbar-global .navbar-nav > li {
      position: static;
  }
  .carousel-caption p{
    font-size:38px;
  }
  .sighting{
    border: 0px none;
  }
}
@media (min-width: $screen-lg-min) {
  .navbar-primary .nav > li > a{
    padding: 20px;
  }
  .carousel-caption p{
    font-size:42px;
  }
  .sighting{
    border-bottom: 1px dotted #ccc;
  }
  footer{
    .footer-nav{
      flex-flow: row;
      > li {
        ul li{
          display: block;
        }
      }
    }
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1400px;
  }
}
